<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Superior Assessment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- advance serach -->
            <div class="d-flex">
              <div class="d-flex flex-column me-2 mb-3">
                <label class="col-form-label">Program</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="
                      (options) => program.short_name != options.short_name
                    "
                    @option:selected="findBatch_programFilter(program)"
                  >
                  </v-select>
                </div>
              </div>
              <div class="d-flex flex-column me-2">
                <label class="col-form-label">Batch</label>
                <div style="width: 180px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batchs"
                    :clearable="false"
                    :selectable="(options) => batch.id != options.id"
                    @option:selected="FilterData(program, batch)"
                  >
                  </v-select>
                </div>
              </div>
              <div class="d-flex mt-4">
                <!-- <div class="col-form-label d-none">hfhd</div> -->
                <div @click="refreshData()" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>
            <!--end advance serach -->

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="ojt-assessment-schedule-list-datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>Level</th>
                    <th>Total Score</th>
                    <th>Assessor</th>
                    <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template v-for="(item, index) in ojtAssessment" :key="index">
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ item.employee_name }}</td>
                      <td>{{ item.program_name }}</td>
                      <td>{{ item.batch }}</td>
                      <td>{{ item.level }}</td>
                      <td>{{ item.total_score }}</td>
                      <td>{{ item.assessor_name }}</td>
                      <td v-if="hasPermissions(['module-group-edit'])">
                        <span>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'trainer-ojt-assessment-detail',
                              params: {
                                id: item.id,
                              },
                            }"
                          >
                            <button
                              type="button"
                              class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                            >
                              <i class="fas fa-eye"></i>
                              View
                            </button>
                          </router-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();

    // Access user.employee_id using computed property

    return { toast };
  },
  data() {
    return {
      ojtAssessment: [],
      programs: this.$store.getters["odoo/getAllPrograms"],
      batchs: [],
      program: "",
      batch: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      //  
    };
  },
  components: {
    vSelect,
  },
  methods: {
    async getOjtAssessment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/superior-assessment`)
        .then((response) => {
          this.ojtAssessment = response.data.data.filter((item) => {
            return item.total_score > 0;
          });

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#ojt-assessment-schedule-list-datatable").DataTable();
    },
    async findBatch_programFilter(program) {
      this.batch = "";
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batchs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });

      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = program?.short_name == data[2] ? true : false;
        console.log("checl-prog", check_program);
        return check_program ? 1 : 0;
      });
      $("#ojt-assessment-schedule-list-datatable").DataTable().draw();
    },
    FilterData(program, batch) {
      this.clearFilter();
      // alert('reach');
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = program?.short_name == data[2] ? true : false;
        const check_batch = batch?.batch_no == data[3] ? true : false;

        return check_program && check_batch ? true : false;
      });
      $("#ojt-assessment-schedule-list-datatable").DataTable().draw();
    },
    refreshData() {
      (this.ojtAssessment = []), (this.ass = []), (this.month = "");
      this.year = "";
      this.months = [];
      this.years = [];
      this.yearId = "";
      this.batchs = [];
      this.batch = "";
      this.program = "";
      this.clearFilter();
      this.getOjtAssessment();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#ojt-assessment-schedule-list-datatable").DataTable().draw();
    },
    async getBatch(program_id) {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
  },

  created() {
    this.clearFilter();
    this.getOjtAssessment();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
.status {
  padding: 0.4em;
}
</style>
